import { createApp } from 'vue';
import { createPinia } from 'pinia';
import HighchartsVue from 'highcharts-vue';
import AsyncComputed from 'vue-async-computed';
import { Vue3Mq } from 'vue3-mq';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import { VBTooltip } from 'bootstrap-vue';
import featureFlag from '@/plugins/featureFlag.js';
import formatDate from '@/plugins/formatDate.js';
import dayjs from '@/plugins/dayjs.js';
import sidebarVisible from '@/plugins/sidebarVisible.js';
import handleErrors from '@/plugins/handleErrors.js';
import filterMaintainableEntityTree from '@/plugins/maintainable-entity/filterTree.js';
import Currency from '@/components/Currency.vue';
import currency from '@/plugins/currency.js';
import currencyPrefix from '@/plugins/currencyPrefix.js';
import Layout from '@/components/app/layouts/Layout.vue';
import DefaultLayout from '@/components/app/layouts/DefaultLayout.vue';
import ExternalLayout from '@/components/app/layouts/ExternalLayout.vue';
import Icons from '@/plugins/icons.js';
import ConfigVarAPI from '@/services/ConfigVarAPI.js';
import ViewHeader from '@/ux/ViewHeader.vue';
import SingleAsset from '@/components/workOrder/assetSelector/SingleAsset.vue';
import * as Sentry from "@sentry/vue";
import '@/lang/dayjs-locales.js';
import AppConfig from '@/config/AppConfig.js';
import CONFIG from '@/config/config.js';
import { initAxios } from '@/axios.js';
import types from '@/store/types/authorization.js';
// import vuelidateErrorExtractor from 'vuelidate-error-extractor';
import isAxiosErrorBlacklisted from '@/util/isAxiosErrorBlacklisted.js';
import mitt from 'mitt';
import filters from './filters/index.js';
import App from './App.vue';
import store from './store/index.js';
import router from './router/index.js';
import loadPolyfills from './polyfills/index.js';
import {createI18n} from 'vue-i18n';
import LANGS from '@/lang/langs';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
// CSS
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import '@/assets/sass/app.scss';

// Vue.use(vuelidateErrorExtractor, {
//   template: formGroupAlt,
//   i18n: 'validation',
//   i18nAttributes: {
//     __default: 'attributes',
//   },
//   name: 'formGroup',
// });

const i18n = createI18n({
  locale: 'en',
  allowComposition: true,
  warnHtmlInMessage: 'off',
  messages: {
    en: LANGS.en,
    fr: LANGS.fr,
  },
});

// Enum setup
import ActionCreator from '@/enums/Action';
import ApiStatusCreator from '@/enums/API.js';
import ApplicableEntityTypeCreator from '@/enums/ApplicableEntityType.js';
import AssetServiceDetailStatusCreator from "@/enums/AssetServiceDetailStatus";
import LocationRestrictionSelectorOptionsCreator from '@/enums/LocationRestrictionSelectorOptions.js';
import AssetStatusCreator from '@/enums/AssetStatus.js';
import BudgetSiteSelectionCreator from '@/enums/BudgetSiteSelectionType.js';
import CommentVisibilityCreator from '@/enums/CommentVisibility.js';
import ComplianceSubmissionApprovalStatusCreator from '@/enums/ComplianceSubmissionApprovalStatus.js';
import ComplianceSubmissionStatusCreator from '@/enums/ComplianceSubmissionStatus.js';
import ContractorHoursDaysCreator from '@/enums/ContractorHoursDays.js';
import ContractorBillingRoundingCreator from '@/enums/ContractorBillingRounding.js';
import ContractorBillingTypeCreator from '@/enums/ContractorBillingType.js';
import ContractorQuestionAnswerCreator from "@/enums/ContractorQuestionAnswer";
import CostItemTypeCreator from '@/enums/CostItemType.js';
import CostProposalStatusCreator from '@/enums/CostProposalStatus.js';
import CostRequestStatusCreator from '@/enums/CostRequestStatus.js';
import CostStatusCreator from '@/enums/CostStatus.js';
import CustomFieldEntityCreator from '@/enums/CustomFieldEntity.js';
import CustomFieldFieldTypeCreator from '@/enums/CustomFieldFieldType.js';
import EngineerTypeCreator from "@/enums/EngineerType";
import InvoiceStatusCreator from '@/enums/InvoiceStatus.js';
import LinkTypeCreator from '@/enums/LinkType.js';
import MaintainableEntityTypeCreator from '@/enums/MaintainableEntityType.js'; // TODO: Multiple responsibilities
import OpenQuoteRequestsFilterCreator from '@/enums/OpenQuoteRequestsFilterOptions.js';
import RamsTypeCreator from '@/enums/RamsType.js';
import RelativeDateRangeCreator from '@/enums/RelativeDateRange.js';
import RelativeDateRangeFutureCreator from '@/enums/RelativeDateRangeFuture.js';
import RemedialActionStatusCreator from '@/enums/RemedialActionStatus';
import ServiceTypesServedOptionsCreator from '@/enums/ServiceTypesServedOptions.js';
import SessionCreator from '@/enums/Session.js';
import StartWorkCheckStatusCreator from "@/enums/StartWorkCheckStatus";
import StartWorkCheckTaskAnswerCreator from "@/enums/StartWorkCheckTaskAnswer";
import StartWorkCheckTaskTypeCreator from "@/enums/StartWorkCheckTaskType";
import UserTypeCreator from '@/enums/UserType.js';
import WorkOrderBillingStatusCreator from '@/enums/WorkOrderBillingStatus.js';
import WorkOrderClosureReasonsCreator from '@/enums/WorkOrderClosureReasons.js';
import WorkOrderCostStatusCreator from '@/enums/WorkOrderCostStatus.js';
import WorkOrderLinkTypeCreator from '@/enums/WorkOrderLinkTypes.js';
import WorkOrderRequestForServiceStatusCreator from '@/enums/WorkOrderRequestForServiceStatus.js';
import WorkOrderResolutionCodesCreator from '@/enums/WorkOrderResolutionCodes.js';
import WorkOrderSlasCreator from '@/enums/WorkOrderSlas.js';
import WorkOrderSlaTypesCreator from '@/enums/WorkOrderSlaTypes.js';
import WorkOrderStateStatusCreator from '@/enums/WorkOrderStateStatus.js';
import WorkOrderWorkflowPrototypeCreator from '@/enums/WorkOrderWorkflowPrototype.js';
import WorkOrderTimeLogSourceCreator from '@/enums/WorkOrderTimeLogSource.js';
import axios from "axios";
import userAPI from "@/services/UserAPI";


const ACTION = ActionCreator();
const API_STATUS = ApiStatusCreator();
const APPLICABLE_ENTITY_TYPE = ApplicableEntityTypeCreator(i18n);
const ASSET_SERVICE_DETAIL_STATUS = AssetServiceDetailStatusCreator(i18n);
const ASSET_STATUS = AssetStatusCreator(i18n);
const BUDGET_SITE_SELECTION_TYPE = BudgetSiteSelectionCreator(i18n);
const COMMENT_VISIBILITY = CommentVisibilityCreator(i18n);
const COMPLIANCE_SUBMISSION_APPROVAL_STATUS = ComplianceSubmissionApprovalStatusCreator(i18n);
const COMPLIANCE_SUBMISSION_STATUS = ComplianceSubmissionStatusCreator(i18n);
const CONTRACTOR_HOURS_DAYS = ContractorHoursDaysCreator(i18n);
const CONTRACTOR_BILLING_ROUNDING = ContractorBillingRoundingCreator(i18n);
const CONTRACTOR_BILLING_TYPE = ContractorBillingTypeCreator(i18n);
const CONTRACTOR_QUESTION_ANSWER = ContractorQuestionAnswerCreator(i18n);
const COST_ITEM_TYPE = CostItemTypeCreator(i18n);
const COST_PROPOSAL_STATUS = CostProposalStatusCreator(i18n);
const COST_REQUEST_STATUS = CostRequestStatusCreator(i18n);
const COST_STATUS = CostStatusCreator(i18n);
const CUSTOM_FIELD_ENTITY = CustomFieldEntityCreator(i18n);
const CUSTOM_FIELD_FIELD_TYPE = CustomFieldFieldTypeCreator(i18n);
const ENGINEER_TYPE = EngineerTypeCreator(i18n);
const INVOICE_STATUS = InvoiceStatusCreator(i18n);
const LINK_TYPE = LinkTypeCreator(i18n);
const LOCATION_RESTRICTION_SELECTOR_OPTIONS = LocationRestrictionSelectorOptionsCreator();
const MAINTAINABLE_ENTITY_TYPE = MaintainableEntityTypeCreator();
const OPEN_QUOTE_REQUESTS_FILTER_OPTIONS = OpenQuoteRequestsFilterCreator(i18n);
const RAMS_TYPE = RamsTypeCreator(i18n);
const RELATIVE_DATE_RANGE = RelativeDateRangeCreator(i18n);
const RELATIVE_DATE_RANGE_FUTURE = RelativeDateRangeFutureCreator(i18n);
const REMEDIAL_ACTION_STATUS = RemedialActionStatusCreator(i18n);
const SERVICE_TYPES_SERVED_OPTIONS = ServiceTypesServedOptionsCreator();
const SESSION = SessionCreator();
const START_WORK_CHECK_STATUS = StartWorkCheckStatusCreator(i18n);
const START_WORK_CHECK_TASK_ANSWER = StartWorkCheckTaskAnswerCreator(i18n);
const START_WORK_CHECK_TASK_TYPE = StartWorkCheckTaskTypeCreator(i18n);
const USER_TYPE = UserTypeCreator();
const WORK_ORDER_BILLING_STATUS = WorkOrderBillingStatusCreator(i18n);
const WORK_ORDER_CLOSURE_REASONS = WorkOrderClosureReasonsCreator(i18n);
const WORK_ORDER_COST_STATUS = WorkOrderCostStatusCreator(i18n);
const WORK_ORDER_LINK_TYPES = WorkOrderLinkTypeCreator(i18n);
const WORK_ORDER_REQUEST_FOR_SERVICE_STATUS = WorkOrderRequestForServiceStatusCreator(i18n);
const WORK_ORDER_RESOLUTION_CODES = WorkOrderResolutionCodesCreator(i18n);
const WORK_ORDER_SLAS = WorkOrderSlasCreator(i18n);
const WORK_ORDER_SLA_TYPES = WorkOrderSlaTypesCreator(i18n);
const WORK_ORDER_STATE_STATUS = WorkOrderStateStatusCreator(i18n);
const WORK_ORDER_TIME_LOG_SOURCE = WorkOrderTimeLogSourceCreator();
const WORK_ORDER_WORKFLOW_PROTOTYPE = WorkOrderWorkflowPrototypeCreator(i18n);

const enums = {
  ACTION,
  API_STATUS,
  APPLICABLE_ENTITY_TYPE,
  ASSET_SERVICE_DETAIL_STATUS,
  ASSET_STATUS,
  BUDGET_SITE_SELECTION_TYPE,
  COMMENT_VISIBILITY,
  COMPLIANCE_SUBMISSION_STATUS,
  COMPLIANCE_SUBMISSION_APPROVAL_STATUS,
  CONTRACTOR_HOURS_DAYS,
  CONTRACTOR_BILLING_ROUNDING,
  CONTRACTOR_BILLING_TYPE,
  CONTRACTOR_QUESTION_ANSWER,
  COST_PROPOSAL_STATUS,
  COST_REQUEST_STATUS,
  COST_ITEM_TYPE,
  COST_STATUS,
  CUSTOM_FIELD_ENTITY,
  CUSTOM_FIELD_FIELD_TYPE,
  ENGINEER_TYPE,
  INVOICE_STATUS,
  LINK_TYPE,
  LOCATION_RESTRICTION_SELECTOR_OPTIONS,
  MAINTAINABLE_ENTITY_TYPE,
  OPEN_QUOTE_REQUESTS_FILTER_OPTIONS,
  RAMS_TYPE,
  RELATIVE_DATE_RANGE,
  RELATIVE_DATE_RANGE_FUTURE,
  REMEDIAL_ACTION_STATUS,
  SERVICE_TYPES_SERVED_OPTIONS,
  SESSION,
  START_WORK_CHECK_STATUS,
  START_WORK_CHECK_TASK_ANSWER,
  START_WORK_CHECK_TASK_TYPE,
  USER_TYPE,
  WORK_ORDER_CLOSURE_REASONS,
  WORK_ORDER_COST_STATUS,
  WORK_ORDER_BILLING_STATUS,
  WORK_ORDER_STATE_STATUS,
  WORK_ORDER_LINK_TYPES,
  WORK_ORDER_RESOLUTION_CODES,
  WORK_ORDER_SLAS,
  WORK_ORDER_SLA_TYPES,
  WORK_ORDER_REQUEST_FOR_SERVICE_STATUS,
  WORK_ORDER_TIME_LOG_SOURCE,
  WORK_ORDER_WORKFLOW_PROTOTYPE,
}

const app = createApp({render: (h) => h(App)});

// we use this `launch` function to prevent the app launching before the vuex-persist has restored
// the state.
const bootstrapApp = async () => {
  ChurnZero.push(['setAppKey', CONFIG.CHURNZERO_APP_KEY]);

  await loadPolyfills();

  app.use(store);
  app.use(router);

  const loggedIn = store.getters.isLoggedIn;
  if (loggedIn) {
    axios({
      method: 'GET',
      url: '/api/client_reference',
      responseType: 'json',
    }).then((clientReferenceResponse) => {
      app.config.globalProperties.$clientReference = clientReferenceResponse.data?.client_reference;

      userAPI.getMe().then((userResponse) => {
        ChurnZero.push(['setContact', clientReferenceResponse.data?.client_reference, userResponse.email]);
        ChurnZero.push([
          'setAttribute',
          'contact',
          {
            'Expansive User Type': userResponse.user_type,
            'Expansive User Role': userResponse.role?.name,
          }
        ]);
      });
    });
  }

  // Custom router handling
  router.beforeEach(async (to, from, next) => {
    ChurnZero.push(['trackEvent', 'Route', '', 1, {
      from: from.fullPath,
      to: to.fullPath,
    }]);
    await store.restored;

    // check for proper authentication where needed based on router setting
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const { permission, permissionOr } = to.meta;

    // eslint-disable-next-line max-len
    const hasPermission = permission
      ? store.getters.hasPermission(permission)
      : store.getters.hasPermissionOr(permissionOr);
    const loggedIn = store.getters.isLoggedIn;
    const { isForcedToChangePassword } = store.getters;

    if (requiresAuth && !loggedIn) {
      // force to login page
      next({name: 'login'});
      return;
    }

    if (isForcedToChangePassword && loggedIn && to.name !== 'ForcedPasswordChange') {
      // force to password change
      next({name: 'ForcedPasswordChange'});
      return;
    }

    switch (to.name) {
      case 'login':
        if (loggedIn) {
          next('/');
        } else {
          next();
        }
        break;
      default:
        if (hasPermission) {
          next();
        } else {
          next({name: 'NoPermission'});
        }
    }
  });

  const emitter = mitt();
  const metaManager = createMetaManager();
  const pinia = createPinia();

  // wait for the vuex-persist to restore the session
  await store.restored;
  initAxios(store, router);

  // vuex-persist doesn't hit mutations when it restores, so we hit them here
  store.commit(types.mutations.SET_SESSION, store.state.authorization.session);
  await store.commit(types.mutations.SET_SESSION_USER, store.state.authorization.user);

  if (store.getters.isLoggedIn) {
    await store.dispatch(types.actions.REFRESH_USER);
  }

  const emptyImage = await import('@/assets/images/photo-missing.png').then((result) => {
    return result.default;
  });

  if ('serviceWorker' in navigator) {
    await navigator.serviceWorker.register(
      import.meta.env.MODE === 'production' ? '/service-worker.js' : '/dev-sw.js?dev-sw',
      { type: import.meta.env.MODE === 'production' ? 'classic' : 'module' }
    )
  }

  // setup laravel echo to connect to the relevant soketi server/app
  // we grab some config from the backend env
  axios.get(
    'pusher_app'
  ).then((response) => {
    window.Pusher = Pusher;

    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: response.data.key,
      wsHost: response.data.host,
      wsPort: response.data.port,
      wssPort: response.data. port,
      forceTLS: false,
      encrypted: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      cluster: 'eu',
    });

    app.config.globalProperties.$echo = window.Echo;
  });

  window.appConfig = await ConfigVarAPI.getConfigVars(null);
  app.config.globalProperties.$configVars = window.appConfig;
  app.config.globalProperties.$globalEmit = emitter;
  app.config.globalProperties.$enums = enums;
  app.config.globalProperties.$emptyImage = emptyImage;

  app.use(AppConfig);
  app.use(currency, store);
  app.use(currencyPrefix, store);
  app.use(dayjs, store);
  app.use(featureFlag);
  app.use(filters);
  app.use(filterMaintainableEntityTree);
  app.use(formatDate, store);
  app.use(handleErrors);
  app.use(HighchartsVue);
  app.use(i18n);
  app.use(Icons);
  app.use(metaManager);
  app.use(metaPlugin);
  app.use(pinia);
  app.use(PrimeVue);
  app.use(sidebarVisible);
  app.use(ToastService);
  app.use(AsyncComputed);

  app.component('Layout', Layout);
  app.component('DefaultLayout', DefaultLayout);
  app.component('ExternalLayout', ExternalLayout);
  app.component('ViewHeader', ViewHeader);
  app.component('currency', Currency);
  app.component('SingleAsset', SingleAsset);
  app.directive('b-tooltip', VBTooltip);

  app.use(Vue3Mq, {
    breakpoints: {
      xs: 0,
      sm: 577,
      md: 768,
      lg: 992,
      xl: 1200,
    },
    defaultBreakpoint: 'xs',
  });

  // Initialise Sentry
  Sentry.init({
    app,
    dsn: CONFIG.SENTRY_DSN,
    integrations: [],

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,

    tracingOptions: {
      trackComponents: true,
    },
    release: CONFIG.RELEASE,
    environment: CONFIG.NODE_ENV,
    autoSessionTracking: true,
    logErrors: true,
    tracesSampleRate: 1.0,
  });

  app.config.errorHandler = (err) => {
    if (!isAxiosErrorBlacklisted(err.message)) {
      Sentry.captureException(err);
    }

    // Enable this if the error thrown is missing important context
    // console.log(err);
    throw new Error(err);
  };
};

bootstrapApp().then(() => app.mount('#app'));
